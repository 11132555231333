import HomePage from '@components/HomePage'
import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'

const Home: NextPage = () => {
  return (
    <>
        <Head>
            <meta name="description" content="Servicii de software și marketing digital"></meta>
        </Head>
        
        <HomePage />
    </>
  );
}

export default Home;